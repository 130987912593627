import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  //mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

   
    { 
      path: '*', 
      //component: NotFoundComponent,
      component: () => import('@/views/dashboard/Page404'),
      name: 'NotFound'
    },
    
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),

      //component: () => import('@/views/dashboard/Login'),
      children: [
        // Dashboard

        {
          name: 'Dashboard',
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },

        {
          name: 'Dados',
          path: 'dados',
          component: () => import('@/views/dashboard/Dados'),
        },

        {
          name: 'Venda',
          path: 'venda',
          component: () => import('@/views/dashboard/Venda'),
        },
        
       
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/dashboard/Register'),
        },

        {
          name: 'Indicacão',
          path: 'indicacao',
          component: () => import('@/views/dashboard/Indicacao'),
        },


        {
          name: 'Loja',
          path: 'loja',
          component: () => import('@/views/dashboard/Loja'),
        }
            
      ],
      
      
    },
    {
      path: '/lgpd',
      component: () => import('@/views/dashboard/Lgpd'),
    },

    {
      path: '/login',
      component: () => import('@/views/dashboard/Login'),
    },
    {
      path: '/cadastro',
      component: () => import('@/views/dashboard/Cadastro'),
    },
    {
      path: '/restrito/atualizacao',
      component: () => import('@/views/restrito/Atualizacao'),
    },

  ],
})
