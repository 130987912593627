import axios from 'axios'
//const url = 'http://localhost:8091/api/'
const url = 'https://maestrix.grupoparque.com.br:8443/apiprevida/api/'

export const http = axios.create({
     baseURL: url, 
})

http.defaults.timeout = 2500;

http.interceptors.response.use(function (response) {
     return response;
   }, function (error) {
     return Promise.reject(error);
   });


export default {
     url,
}